import React from 'react';
import { Link } from 'gatsby';

const Header = props => (
  <header className="w-full">
    <nav className="max-w-6xl mx-auto flex justify-between items-center px-4 xl:px-0 py-6">
      <Link
        to={props.isEnglish ? '/en' : '/'}
        className="lato font-black text-xl text-teal-700"
      >
        Induba SRL
      </Link>
      <nav className="flex items-center">
        <Link
          to={props.isEnglish ? '/' : '/en'}
          className="text-sm hover:underline text-gray-500"
        >
          {props.isEnglish ? 'Cambia a Español' : 'Change to English'}
        </Link>
        <a
          href={props.isEnglish ? '/en/#products' : '/#productos'}
          className="hidden md:inline-block text-base md:text-lg font-semibold text-teal-700 ml-4 md:ml-8"
        >
          {props.isEnglish ? 'Products' : 'Productos'}
        </a>
        <Link
          to={props.isEnglish ? '/en/contact' : '/contacto'}
          className="text-base md:text-lg font-semibold text-teal-700 ml-4 md:ml-8"
        >
          {props.isEnglish ? 'Contact' : 'Contacto'}
        </Link>
      </nav>
    </nav>
  </header>
);

export default Header;
