import React from 'react';
import { Link } from 'gatsby';

const Footer = props => (
  <footer className="max-w-6xl mx-auto">
    <div className=" mt-20 py-12 mx-4 xl:mx-0 lg:py-16 border-t border-gray-400">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="flex flex-col justify-center md:justify-start">
          <h3 className="font-extrabold text-2xl text-teal-700">Induba SRL</h3>
          <address className="mt-2 text-gray-800 text-base leading-6 block">
            Ruta Nacional 12 Km. 131369 Candelaria, Misiones
          </address>
          <span className="mt-2 block text-gray-800">
            Tel:{' '}
            <a href="tel:+54-3765-051777" className="text-base leading-6">
              +54 376 5051777
            </a>
          </span>
          <a
            href="mailto:indubasrl@yahoo.com.ar"
            className="mt-2 text-blue-600 underline text-base leading-6 block"
          >
            indubasrl@yahoo.com.ar
          </a>
        </div>
        <div className="flex justify-start md:justify-end mt-12 md:mt-0">
          <div>
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-teal-700 uppercase">
              {props.isEnglish ? 'Company' : 'La Empresa'}
            </h4>
            <ul className="mt-4">
              <li>
                <Link
                  to={props.isEnglish ? '/en/history/' : '/historia/'}
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'History' : 'Historia'}
                </Link>
              </li>
              <li className="mt-4">
                <Link
                  to={props.isEnglish ? '/en/about/' : '/quienes-somos/'}
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'About Us' : 'Quiénes Somos'}
                </Link>
              </li>
              {/* <li className="mt-4">
                <Link
                  to={'/sustentabilidad/'}
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  Sustentabilidad
                </Link>
              </li> */}
              <li className="mt-4">
                <Link
                  to={props.isEnglish ? '/en/contact/' : '/contacto/'}
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'Contact' : 'Contacto'}
                </Link>
              </li>
              <li className="mt-4">
                <Link
                  to={props.isEnglish ? '/' : '/en'}
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'Sitio en Español' : 'English Site'}
                </Link>
              </li>
            </ul>
          </div>
          <div className="ml-20">
            <h4 className="text-sm leading-5 font-semibold tracking-wider text-teal-700 uppercase">
              {props.isEnglish ? 'Products' : 'Productos'}
            </h4>
            <ul className="mt-4">
              <li>
                <Link
                  className="text-base leading-6 text-blue-700 hover:underline"
                  to={
                    props.isEnglish
                      ? '/en/products/broom-handles'
                      : '/productos/cabos-de-escoba'
                  }
                >
                  {props.isEnglish ? 'Broom Handles' : 'Cabo de Escoba'}
                </Link>
              </li>
              <li className="mt-4">
                <Link
                  to={
                    props.isEnglish
                      ? '/en/products/door-frames'
                      : '/productos/molduras'
                  }
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'Door Frames' : 'Molduras'}
                </Link>
              </li>
              <li className="mt-4">
                <Link
                  to={
                    props.isEnglish
                      ? '/en/products/wooden-boards'
                      : '/productos/tableros'
                  }
                  className="text-base leading-6 text-blue-700 hover:underline"
                >
                  {props.isEnglish ? 'Pine Boards' : 'Tableros'}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="flex justify-center flex-col md:justify-between items-center mt-16 pt-4 px-4 xl:px-0">
      <p className="text-base leading-6 text-gray-800">
        &copy; 2020 Induba SRL
      </p>
      <div className="flex items-center text-gray-800 text-opacity-50">
        Por
        <span className="m-2 text-2xl" role="img" aria-label="flag emojis">
          🇦🇷
        </span>
        con amor de
        <span className="m-2 text-2xl" role="img" aria-label="flag emojis">
          🇺🇸
        </span>
      </div>
    </div>
  </footer>
);

export default Footer;
